$bodyBg: #F3F4FA;
$mainBg: #fff;
// $mainColor: #8624DB;
$mainColor: #002868;
$txtColor: #4E4D4F;
$purple: #8624DB;
$blue: #002868;
$lightblue: #ADD8E6;
$orange: #FF9066;
$yellow: #f1b900;
$white: #fff;
$black: #000;
$green: #4CAF50;
$red: #DB190C;

$fontFamily: 'Roboto', sans-serif;

$fontSizeTiny: .75rem;
$fontSizeSmall: 1rem;
$fontSizeNormal: 1.125rem;
$fontSizeMedium: 1.25rem;
$fontSizeLarge: 1.5625rem;

$sideBarWidth: 384px;
$sideBarLogoHeight: 171px;
$spacing: 48px;
$midSpacing: 32px;
$mobileSpacing: 24px;
$mobileMidSpacing: 12px;
$borderRadius: 30px;

$mobile-width: 600px;
$tablet-width: 1366px;
