@use './abstracts/' as *;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

body {
    font-family: $fontFamily;
    color: $txtColor;
    font-size: $fontSizeNormal;
    line-height: 1.5;
    background-color: $bodyBg;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: unset;
}

img {
    width: 100%;
}

ul,
li {
    list-style-type: none;
}

.tb {
    margin-top: $spacing;

    @include tablet {
        margin-top: $mobileSpacing;
    }
}

.mb {
    margin-bottom: $spacing;

    @include tablet {
        margin-bottom: $mobileSpacing;
    }
}

.mbm {
    margin-bottom: $midSpacing;

    @include tablet {
        margin-bottom: $mobileMidSpacing;
    }
}

.title {
    font-size: $fontSizeMedium;
    font-weight: 700;
}

.subtitle {
    font-size: $fontSizeNormal;
    font-weight: 700;
}

.subtitlelo {
    font-size: $fontSizeNormal;
    font-weight: 300;
}

.label {
    font-size: $fontSizeSmall;
}

.option {
    font-size: $fontSizeTiny;
}

.hspacer {
    width: 10%
}

.txt-error {
    color: $red;
    font-size: $fontSizeSmall;
}

.txt-success {
    color: $green;
}

.txt-danger { 
    color: $red;
}

.qr-container {
    height: 128px;
    width: 128px;
}

.spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.spinnersmall {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.checkboxes {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    word-wrap: break-word;
}

.checkboxes input {
    width: 15%;
    margin-bottom: 0px;
}

.centered-text {
    text-align: center;
}

form {
    max-width: 500px;
    margin: 0 auto;
}
  
h1 {
    font-weight: 100;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
}
  
.form {
    background: #0e101c;
    max-width: 400px;
    margin: 0 auto;
}
  
p {
    color: #bf1650;
}
  
p::before {
    display: inline;
    content: "⚠ ";
}
  
input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid white;
    background-color: #F3F4FA;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}

.entry {
    width: 50%;
    margin-bottom: 0;
}

.splitcontent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.hcenter {
    display: flex;
    align-items: center;
}
  
label {
    line-height: 2;
    text-align: left;
    display: block;
    font-size: 14px;
    font-weight: 200;
}

input[type="radio"] {
    display: unset;
    width: unset;
}

  
button[type="submit"],
input[type="submit"] {
    background: $blue;
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;
  }
  
button[type="submit"]:hover,
input[type="submit"]:hover {
    background: $lightblue;
    cursor:pointer;
  }
  
button[type="submit"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }
  
input:disabled {
    opacity: 0.4;
  }
  
input[type="button"]:hover {
    transition: 0.3s all;
  }
  
button[type="submit"],
input[type="button"],
input[type="submit"] {
    -webkit-appearance: none;
  }
  
  
button[type="button"] {
    display: block;
    appearance: none;
    background: #333;
    color: white;
    border: none;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 4px;
}
  
hr {
    margin-top: 30px;
}
  
button {
    display: block;
    appearance: none;
    margin-top: 40px;
    border: 1px solid #333;
    margin-bottom: 20px;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 4px;
}